import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {API_GAME_USER_INFO} from 'helpers/config';
import { notification } from 'antd';
import { postJSON } from 'helpers/common';

export interface userModuleData {
  module_id: string,
  active: boolean
}

export interface userGameInfoData {
  maes: integer,
  resources: any,
  items: [],
  modules: any
}

interface userGameInfoState {
  gameInfo: userGameInfoData
  status: string
}

const initialState: userGameInfoState = {
  gameInfo: {
    maes: 0,
    resources: undefined,
    items: [],
    modules: undefined
  },
  status: 'loading'
};

interface buildNewModulePayload {
  module_id: string
}

export const gameUserInfoSlide = createSlice({
  name: 'allModules',
  initialState: initialState,
  reducers: {
    buildNewModule: (state, action: PayloadAction<buildNewModulePayload>) => {
      state.gameInfo.modules[action.payload.module_id] = true;
    },
    reset: () => initialState
  },
  extraReducers: (builder) => builder
    .addCase(loadUserGameInfo.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(loadUserGameInfo.rejected, (state) => {
      state.status = 'ready';
      notification.error({message: 'Somethings wrong when loading Shool Modules'});
    })
    .addCase(loadUserGameInfo.fulfilled, (state, action: PayloadAction<userGameInfoData>) => {
      state.gameInfo.maes = action.payload.maes;
      state.gameInfo.items = action.payload.items;
      state.gameInfo.modules = action.payload.modules;
      state.gameInfo.resources = action.payload.resources;
      state.status = 'ready';
    })
})

export const loadUserGameInfo = createAsyncThunk('gameUserInfo/load', async () => {
  const resData = await postJSON(API_GAME_USER_INFO);
  return resData.data;
  });

export const {
  buildNewModule
} = gameUserInfoSlide.actions;

export default gameUserInfoSlide.reducer;
export const loadUserGameInfoSelector = (state: RootState) => state.gameUserInfo.gameInfo;
export const loadUserGameInfoStatus = (state: RootState) => state.gameUserInfo.status;