import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import Cookies from 'js-cookie';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/es/storage';
import {
  API_User_ChangePassword, API_User_ChangeUsername, API_User_Connect_Social,
  API_User_GiveNonNFTs,
  API_User_LinkWallet, API_User_Login,
  API_User_SendForgotPassword, API_User_Signup, API_User_Signup_Google, API_User_Signup_Metamask,
  API_User_VerifyEmail
} from '../../helpers/config';
import { EmailSchema } from '../Util/Validation';
import { notification } from 'antd';

const accountPersistConfig = {
  key: 'account',
  storage: localStorage
}

export type Step =
  'login'
  | 'user-info'
  | 'register'
  | 'logged-in'
  | 'verify-email'
  | 'register-thanks-you'
  | 'reset-password'
  | 'change-email';
export type WalletType = 'walletconnect' | 'injected';
export interface AccountState {
  step: Step;
  username?: string;
  usernameChecked?: boolean;
  firstName?: string;
  lastName?: string;
  refID?: string,
  email?: string;
  fbToken?: string;
  googleToken?: string;
  walletSign?: string;
  token?: string;
  uid?: string;
  tokenRefreshTime?: Date;
  wallet?: string;
  walletType?: WalletType;
  requestStatus?: 'loading' | 'ready' | 'success' | 'fail' | 'fgpw-success' | 'rspw-success';
  tokenExpiredTime?: Date;
  isVerifiedEmail?: boolean;
  shard?: number;
  facebookId?: string;
  formErrors?: any;
  picture?: string;
  points?: number;
  verifyStatus?: 'loading' | 'success' | 'fail';
  forgotPasswordStatus?: 'loading' | 'success' | 'fail';
  errorMessage?: string;
  giveNonNFTsStatus?: 'loading' | 'success' | 'fail';
  linkWalletStatus?: 'loading' | 'success' | 'fail';
}


const initialState: AccountState = {
  step: 'login',
  username: undefined,
  usernameChecked: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  fbToken: undefined,
  token: undefined,
  wallet: undefined,
  uid: undefined,
  tokenRefreshTime: undefined,
  tokenExpiredTime: undefined,
  requestStatus: 'ready',
  verifyStatus: 'loading',
};

interface TokenInfo {
  uid: undefined,
  tokenRefreshTime: undefined,
  tokenExpiredTime: undefined,
}

interface ResetPasswordFormData {
  password: string,
  _id: string
}

interface ChangeUsernameFormData {
  newUsername: string,
}

interface SignUpFormData {
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  refID?: string,
  agreeTermsAndConditions: boolean
}

interface GoogleSignupFormData {
  provider: 'signUp' | 'resendEmail'
  credential: string,
  email: string,
  firstName: string,
  lastName: string,
  refID?: string,
  agreeTermsAndConditions: boolean
}

interface WalletSignupFormData {
  provider: 'signUp' | 'resendEmail'
  walletSign: string,
  email: string,
  firstName: string,
  lastName: string,
  refID?: string,
  agreeTermsAndConditions: boolean
}

interface GoogleResendVerifyEmail {
  provider: 'signUp' | 'resendEmail'
  credential: string,
  email: string
}

interface UserInfo {
  firstName?: string;
  lastName?: string;
  username?: string;
  refID?: string,
  email?: string;
  wallet?: string;
  uid?: string;
  tokenRefreshTime?: Date;
  isVerifiedEmail?: boolean;
  shard?: number;
  facebookId?: string;
  points?: number;
  walletType: WalletType;
}

function doLogin<State>(data: any, state: any, action: any) {
  let expires = new Date(Date.now() + 4 * 60 * 60 * 1000);
  Cookies.set('oms_uid', data.uid, {path: '/', expires, domain: 'onemagicschool.com'});
  state.uid = data.uid;
  state.step = 'logged-in';
  state.requestStatus = 'ready';
  state.tokenExpiredTime = data.tokenExpiredTime;
  state.tokenRefreshTime = data.tokenRefreshTime;
  state.wallet = data.wallet;
  state.walletType = data.walletType;
  state.username = data.username;
  state.facebookId = data.facebookId;
  state.isVerifiedEmail = action.payload.isVerifiedEmail === true || action.payload.isVerifiedEmail === 1;
  state.email = action.payload.email;
  state.shard = action.payload.shard;
  state.picture = action.payload.picture;
  state.fbToken = action.payload.fbToken;
  state.points = action.payload.points;
}

export const useSlice = createSlice({
  name: 'account',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: () => {
      //deactive metamask
      //deactive walletconnect
      const {metaMask} = require('connectors/metaMask');
      const {walletConnect} = require('connectors/walletConnectV2');
      metaMask?.deactivate?.();
      walletConnect?.deactivate?.();
      return initialState
    },
    changeStep: (state, action: PayloadAction<Step>) => {
      state.step = action.payload;
    },
    resetUserFormRequestStatus: (state) => {
      state.requestStatus = 'ready'
      state.formErrors = {}
    },
    updateUserInfo: (state, action: PayloadAction<UserInfo>) => {
      if (action.payload.email) state.email = action.payload.email;
      if (action.payload.firstName) state.firstName = action.payload.firstName;
      if (action.payload.lastName) state.lastName = action.payload.lastName;
      if (action.payload.uid) state.lastName = action.payload.uid;
      if (action.payload.tokenRefreshTime) state.tokenRefreshTime = action.payload.tokenRefreshTime;
    },
    changeUsernameChecked: (state, action: PayloadAction<boolean | undefined>) => {
      state.usernameChecked = action.payload;
    },
    setUserFormErrorMessage: (state, action: PayloadAction<string | undefined>) => {
      state.errorMessage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.requestStatus = 'loading';
      state.formErrors = {};
    }).addCase(loginUser.fulfilled, (state, action: any) => {
      console.log('loginUser.fulfilled');
      const data = action.payload;
      switch (data.error) {
        case 'GOOGLE_ACCOUNT_NOT_LINKED':
          state.requestStatus = 'ready';
          state.refID = '';
          state.email = data.email;
          state.firstName = data.firstName;
          state.lastName = data.lastName;
          state.googleToken = action.meta.arg.credential || action.meta.arg.accessToken;
          state.step = 'user-info';
          return;
        case 'GOOGLE_ACCOUNT_EMAIL_NOT_VERIFIED':
          state.googleToken = action.meta.arg.credential || action.meta.arg.accessToken;
          state.requestStatus = 'ready';
          state.step = 'change-email';
          return;
        case 'WALLET_ACCOUNT_NOT_LINKED':
          state.requestStatus = 'ready';
          state.refID = '';
          state.email = data.email;
          state.firstName = data.firstName;
          state.lastName = data.lastName;
          state.walletSign = action.meta.arg.sig;
          state.step = 'user-info';
          return;
        case 'WALLET_EMAIL_NOT_VERIFIED':
          state.walletSign = action.meta.arg.sig;
          state.requestStatus = 'ready';
          state.step = 'change-email';
          return;
        case 'LOGIN_FAILED':
          action.asyncDispatch(logout());
          return;
        case 'USER_NOT_FOUND':
          action.asyncDispatch(logout());
          return;
          
      }
      if (data?.error) {
        state.formErrors = {'form': data.error};
        state.requestStatus = 'ready';
        return;
      }
      doLogin(data, state, action);
    }).addCase(loginUser.rejected, (state, action: any) => {
      state.requestStatus = 'ready';
    }).addCase(connectSocial.pending, (state, action: any) => {
      state.requestStatus = 'loading'
    })
      .addCase(connectSocial.fulfilled, (state, action: any) => {
        const data = action.payload;
        if (data.error) {
          state.requestStatus = 'ready';
          return;
        }
        state.facebookId = data.facebookId;
        state.requestStatus = 'ready';
      }).addCase(connectSocial.rejected, (state, action: any) => {
      state.requestStatus = 'ready'
    }).addCase(signUp.pending, (state, action: any) => {
      state.requestStatus = 'loading'
      state.formErrors = {};
    })
      .addCase(signUp.rejected, (state, action: any) => {
        state.requestStatus = 'ready'
      })
      .addCase(signUp.fulfilled, (state, action: any) => {
        const data = action.payload;
        if (data.errors) {
          state.formErrors = data.errors;
        } else {
          state.step = 'register-thanks-you';
        }
        state.requestStatus = 'ready'
      })
      .addCase(verifyEmail.pending, (state) => {
        state.errorMessage = undefined;
        state.verifyStatus = 'loading'
      })
      .addCase(verifyEmail.rejected, (state) => {
        state.verifyStatus = 'fail'
      })
      .addCase(verifyEmail.fulfilled, (state, action: any) => {
        if (action?.payload?.error) {
          state.verifyStatus = 'fail';
          state.errorMessage = action.payload.error;
        } else {
          state.verifyStatus = 'success'
        }
      })
      .addCase(forgotPassword.pending, state => {
        state.errorMessage = undefined;
        state.forgotPasswordStatus = 'loading';
      })
      .addCase(forgotPassword.rejected, state => {
        state.forgotPasswordStatus = 'fail';
        state.errorMessage = 'Forgot password request not success';
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          state.errorMessage = action.payload.error;
          state.forgotPasswordStatus = 'fail';
        } else {
          state.forgotPasswordStatus = 'success';
        }
      })
      .addCase(giveNonNFTs.pending, state => {
        state.errorMessage = undefined;
        state.giveNonNFTsStatus = 'loading';
      })
      .addCase(giveNonNFTs.rejected, state => {
        state.giveNonNFTsStatus = 'fail';
        state.errorMessage = 'Give free NFT not success';
      })
      .addCase(giveNonNFTs.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          state.errorMessage = action.payload.error;
          state.giveNonNFTsStatus = 'fail';
        } else {
          state.giveNonNFTsStatus = 'success';
        }
      })
      .addCase(linkWallet.pending, state => {
        state.linkWalletStatus = 'loading';
        state.errorMessage = undefined;
      })
      .addCase(linkWallet.rejected, state => {
        state.errorMessage = 'Unlink wallet not success';
        state.linkWalletStatus = 'fail';
      })
      .addCase(linkWallet.fulfilled, (state, action) => {
        if (action.payload?.error) {
          state.errorMessage = action.payload.error;
          state.linkWalletStatus = 'fail';
        }
        state.linkWalletStatus = 'success';
      })
      .addCase(sendForgotPassword.pending, state => {
        state.requestStatus = 'loading';
      })
      .addCase(sendForgotPassword.rejected, state => {
        state.requestStatus = 'fail';
        state.errorMessage = 'Forgot password request not success!';
      })
      .addCase(sendForgotPassword.fulfilled, (state, action: any) => {
        if (action.payload?.error || action.payload?.errors) {
          state.requestStatus = 'fail';
        } else {
          state.requestStatus = 'fgpw-success';
        }
      })
      .addCase(resetPassword.pending, state => {
        state.requestStatus = 'loading'
      })
      .addCase(resetPassword.rejected, state => {
        state.requestStatus = 'fail';
        state.errorMessage = 'Reset password request not success!';
      })
      .addCase(resetPassword.fulfilled, (state, action: any) => {
        if (action.payload?.error || action.payload?.errors) {
          state.requestStatus = 'fail';
        } else {
          state.requestStatus = 'rspw-success';
        }
      })
      .addCase(signupGoogle.pending, state => {
        state.requestStatus = 'loading';
      })
      .addCase(signupGoogle.rejected, state => {
        state.requestStatus = 'fail';
        state.errorMessage = 'Google signup not success!';
      })
      .addCase(signupGoogle.fulfilled, (state, action: any) => {
        console.log('signupGoogle.fulfilled');
        const data = action.payload;
        if (data.errors) {
          state.formErrors = data.errors;
          state.requestStatus = 'fail';
        } else {
          state.requestStatus = 'ready';
          state.step = 'logged-in';
          doLogin(data, state, action);
        }
      })
      .addCase(signupWallet.pending, state => {
        state.requestStatus = 'loading';
      })
      .addCase(signupWallet.rejected, state => {
        state.requestStatus = 'fail';
        state.errorMessage = 'Wallet signup not success!';
      })
      .addCase(signupWallet.fulfilled, (state, action: any) => {
        console.log('signupWallet.fulfilled');
        const data = action.payload;
        if (data.errors) {
          state.formErrors = data.errors;
          state.requestStatus = 'fail';
        } else if (data.error) {
          notification.error({
            message: 'Error',
            description: data.error,
          });
          state.requestStatus = 'fail';
        } else {
          state.requestStatus = 'ready';
          state.step = 'logged-in';
          doLogin(data, state, action);
        }
      })
      .addCase(changeUsername.pending, state => {
        state.requestStatus = 'loading';
      })
      .addCase(changeUsername.rejected, state => {
        state.requestStatus = 'fail';
        state.errorMessage = 'Change username not success!';
      })
      .addCase(changeUsername.fulfilled, (state, action: any) => {
        console.log('changeUsername.fulfilled');
        const data = action.payload;
        if (data.errors) {
          state.formErrors = data.errors;
          state.requestStatus = 'fail';
        } else {
          state.requestStatus = 'ready';
          state.firstName = action.meta.firstName;
          state.lastName = action.meta.lastName;
        }
      })
  }
});

interface LoginFormData {
  username?: string,
  email?: string,
  password?: string,
  credential?: string,
  accessToken?: string,
  firstName?: string,
  lastName?: string,
  refID?: string,
  sig?: string,
  walletType?: WalletType,
  provider: 'Google' | 'Facebook' | 'Token' | 'Password' | 'Email' | 'Wallet'
}

export const signupGoogle = createAsyncThunk('account/signupGoogle',
  async (formData: GoogleSignupFormData | GoogleResendVerifyEmail) => {
    const response = await fetch(String(API_User_Signup_Google), {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  });

export const signupWallet = createAsyncThunk('account/signupWallet', async (formData: WalletSignupFormData) => {
  const response = await fetch(String(API_User_Signup_Metamask), {
    method: 'POST',
    body: JSON.stringify(formData),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });
  return response.json();
});

export const loginUser = createAsyncThunk('account/login',
  async (data: LoginFormData, thunkAPI) => {
    let url = API_User_Login;
    const response = await fetch(String(url), {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      mode: 'cors'
    });
    return response.json();
  });

export const connectSocial = createAsyncThunk('account/connectsocial', async ({provider, accessToken}: {
  provider: string,
  accessToken: string
}) => {
  const response = await fetch(API_User_Connect_Social, {
    method: 'POST',
    body: JSON.stringify({
      provider: provider,
      accessToken: accessToken
    }),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    mode: 'cors'
  });
  return response.json();
})

export const signUp = createAsyncThunk('account/signup', async (formData: SignUpFormData) => {
  const response = await fetch(API_User_Signup, {
    method: 'POST',
    body: JSON.stringify(formData),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    mode: 'cors'
  });
  return response.json();
});
export const verifyEmail = createAsyncThunk('account/verifyemail', async (id: string) => {
  let url = API_User_VerifyEmail;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({_id: id}),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    mode: 'cors'
  });
  return response.json();
});

export const forgotPassword = createAsyncThunk('account/forgotpassword', async (email: string) => {
  let url = API_User_SendForgotPassword;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({email: email}),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    mode: 'cors'
  });
  return response.json();
})

export const giveNonNFTs = createAsyncThunk('account/giveNonNfts', async () => {
  let url = API_User_GiveNonNFTs;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    mode: 'cors'
  });
  return response.json();
})

export const linkWallet = createAsyncThunk('account/linkWallet', async (payload: {
  sig: string,
  walletType: WalletType
}) => {
  let url = API_User_LinkWallet;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload),
    credentials: 'include',
    mode: 'cors'
  });
  return response.json();
});

export const sendForgotPassword = createAsyncThunk('account/forgotPassword', async (email: string) => {
  let url = API_User_SendForgotPassword;
  await EmailSchema.validate(email).then(
    () => {
      return fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: email.toLowerCase()}),
        credentials: 'include',
        mode: 'cors'
      }).then(response => response.json())
    }
  ).catch(err => {
    return err;
  });
})

export const resetPassword = createAsyncThunk('account/resetPassword', async (data: ResetPasswordFormData) => {
  let url = API_User_ChangePassword;
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
    credentials: 'include',
    mode: 'cors'
  }).then(response => response.json())
})

export const changeUsername = createAsyncThunk('account/changeUsername', async (data: ChangeUsernameFormData) => {
  let url = API_User_ChangeUsername;
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
    credentials: 'include',
    mode: 'cors'
  }).then(response => response.json())
})

export const {
  changeStep,
  resetUserFormRequestStatus,
  logout,
  setUserFormErrorMessage
} = useSlice.actions;
export default persistReducer(accountPersistConfig, useSlice.reducer);
export const stepSelector = (state: RootState) => state.account.step;
export const firstNameSelector = (state: RootState) => state.account.firstName;
export const lastNameSelector = (state: RootState) => state.account.lastName;
export const refIDSelector = (state: RootState) => state.account.refID;
export const tokenRefreshTimeSelector = (state: RootState) => state.account.tokenRefreshTime;
export const userInfoSelector = (state: RootState) => state.account as UserInfo;
export const tokenInfoSelector = (state: RootState) => state.account as TokenInfo;
export const requestStatusSelector = (state: RootState) => state.account.requestStatus;
export const walletSelector = (state: RootState) => state.account.wallet;
export const userFormErrorSelector = (state: RootState) => state.account.formErrors;
export const verifyStatusSelector = (state: RootState) => state.account.verifyStatus;
export const googleTokenSelector = (state: RootState) => state.account.googleToken;
export const walletSignSelector = (state: RootState) => state.account.walletSign;
export const userFormErrorMessageSelector = (state: RootState) => state.account.errorMessage;
export const walletTypeSelector = (state: RootState) => state.account.walletType;
