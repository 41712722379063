import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { stepSelector, userInfoSelector } from 'components/UserForm/UseSlice';
import { API_REWARDS_CLAMABLE, API_WHITELIST_GETSHARD } from 'helpers/config';
import * as CommonHelper from 'helpers/common';
import { ethers } from 'ethers';
import { addressList, abiList } from 'helpers/config';
import MainProviderContext from '../../context/MainProvider';

const styles = {
  root: {

    position: 'absolute',
    top: '2rem',
    right: '2rem',
    zIndex: 20,
    width: 200,
    

  },
  account: {
    height: '42px',
    padding: '0 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '12px',
    backgroundColor: 'rgb(244, 244, 244)',
    cursor: 'pointer',
  },
  text: {
    color: '#21BF96',
  },
  connector: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '20px 5px',
    cursor: 'pointer',
  },
  icon: {
    alignSelf: 'center',
    fill: 'rgb(40, 13, 95)',
    flexShrink: '0',
    marginBottom: '8px',
    height: '30px',
  },
} as const;

function Profile() {
  const main_provider = useContext(MainProviderContext);
  const stepLogin = useAppSelector(stepSelector);
  const userInfo = useAppSelector(userInfoSelector);
  const [claimableRewards, setClaimableRewards] = useState(0);
  const [claimableWhitelistRewards, setClaimableWhitelistRewards] = useState(0);
  const [shard, setShard] = useState('0');

  useEffect(() => {
    loadClaimableRewards();
    loadClaimableWhitelistShard();
    loadShard();
  }, [userInfo]);
  
  const loadClaimableRewards = async () => {
    if (!userInfo.uid) return;
    console.log('loadClaimableRewards');
    const res = await CommonHelper.postJSON(API_REWARDS_CLAMABLE, {});
    console.log('loadClaimableRewards', res);
    if (res?.data) {

      setClaimableRewards(res.data);
    }
  };
  const loadClaimableWhitelistShard = async () => {
    if (!userInfo.uid) return;
    console.log('loadClaimableRewards');
    const res = await CommonHelper.postJSON(API_WHITELIST_GETSHARD, {});
    console.log('loadClaimableRewards', res);
    if (res?.shard_whitelist) {
      let amount = ethers.utils.parseEther(res.shard_whitelist.toString());
      console.log(amount, ethers.utils.formatEther(amount))
      setClaimableWhitelistRewards(res.shard_whitelist);
    }

  };
  const loadShard = async () => {
    if (!userInfo.wallet) return;
    const shardContract = new ethers.Contract(
      addressList['ShardAddress'],
      abiList.abiShard,
      main_provider
    );
    const shardBalance = await shardContract.balanceOf(userInfo.wallet);
    setShard(ethers.utils.formatEther(shardBalance));
  }
  return (
    <>
      {userInfo.uid && <div
        style={styles.root}
      >
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <Link aria-disabled={stepLogin !== 'logged-in'} to="/account">
            <div></div>
            <img
              src="./no-profile.png"
              alt="profile avatar"
            />
          </Link>
          <div style={{backgroundColor: 'rgba(0,0,0,0.6)', width: '100%', padding: 10, borderRadius: 10}}>
            <div style={{color: 'white', textAlign: 'center'}}>{userInfo.username}</div>
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '10px'}}>

              <div style={{textAlign: 'right'}}><img src="./shard1.png" width="25px" height="25px"/>:</div>
              <div style={{color: 'white'}}>{userInfo.shard || '0'}</div>

              <div style={{textAlign: 'right', color: 'white'}}>RefID:</div>
              <div style={{color: 'white'}}>{userInfo.uid?.slice(-8).toUpperCase()}</div>

              <div style={{textAlign: 'right', color: 'white'}}>Wallet:</div>
              <div style={{color: 'white'}}>****{userInfo.wallet?.slice(-4).toUpperCase()}</div>

            </div>
          </div>
        </div>
      </div>}
    </>
  );
}

export default Profile;
