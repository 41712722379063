import {
  Typography, Button
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import Phaser from 'phaser';
import { IonPhaser, GameInstance } from '@ion-phaser/react';

import { useAppSelector } from '../../app/hooks';
import { appWelcomeSelector, updateAppWelcome } from '../../app/AppSlice';
import './Home_Phaser.scss';
import classNames from 'classnames';
import SoundControl from 'components/SoundControl/SoundControl';
import { useAppDispatch } from '../../app/hooks';
import Pubsub from 'pubsub-js';
import { gameLoadingSelector, setGameLoading } from './HomePhaserSlice';
import MainScene from './MainScene';
import { menuOpenSelector } from '../MobileMenu/MobileMenuSlice';
import OmsModal from '../CustomComponent/OmsModal';
import { isMobile } from 'react-device-detect';
import {
  stepSelector,
  userInfoSelector
} from '../UserForm/UseSlice';
import { useWeb3React } from '@web3-react/core';
console.log('GameInstance');
const gameConfig: GameInstance = {
  width: '100%',
  height: '100%',
  type: Phaser.AUTO,
  scale: {
    // mode: Phaser.Scale.RESIZE,
    autoCenter: Phaser.Scale.CENTER_BOTH,
    mode: Phaser.Scale.RESIZE,
  },

  render: {
    antialias: false,
    pixelArt: true,
    roundPixels: true,
  },
  transparent: true,
  scene: MainScene,
  plugins: {
    scene: [
      { key: 'SpinePlugin', plugin: window.SpinePlugin, mapping: 'spine' }
    ],
  }
};

interface HomePhaserProps extends React.HTMLAttributes<HTMLDivElement> {

}

const { Title } = Typography;
const PUBSUB_WINDOW_RESIZE = 'window:resize';
const PUBSUB_COMING_SOON_OPEN = 'comingsoon:open';
const PUBSUB_NAVIGATE_TO = 'navigate:to';
const PUBSUB_PHASER_IN = 'phaser:in';
export default function Home(props: HomePhaserProps
) {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(userInfoSelector);
  const appWelcome = useAppSelector(appWelcomeSelector);
  const gameRef = useRef<HTMLIonPhaserElement>(null);
  const [game, setGame] = useState<GameInstance>();
  const [openComingSoon, setOpenComingSoon] = useState<boolean>(false);
  const [, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const location = useLocation();
  const navigate = useNavigate();
  const menuOpen = useAppSelector(menuOpenSelector);
  const initialize = !useAppSelector(gameLoadingSelector);
  const [comingSoonTitle, setComingSoonTitle] = useState<string>('Features coming soon')
  const [comingSoonContent, setComingSoonContent] = useState<string>('This feature is in develop')

  const step = useAppSelector(stepSelector);

  const [showModalLinkWallet, setShowModalLinkWallet] = useState(false);
  const [showInstallMetamask, setShowIntallMetamask] = useState<boolean>(false);
  const { account, provider, isActive } = useWeb3React();
  // const gameUserInfo = useAppSelector(loadUserGameInfoSelector);

  const handleOk = () => {
    setShowModalLinkWallet(false);
  };

  const checkUserLinkWallet = () => {
    if (!userInfo.wallet) {
      setShowModalLinkWallet(true);
    }
  }

  Pubsub.subscribe(PUBSUB_NAVIGATE_TO, (topic: string, href: string) => {
    navigateTo(href);
  })
  Pubsub.subscribe(PUBSUB_COMING_SOON_OPEN, (topic: string, content: any) => {
    console.log('PUBSUB_COMING_SOON_OPEN:running')
    setComingSoonTitle(content.title);
    setComingSoonContent(content.content);
    setOpenComingSoon(true);
  })
  const navigateTo = (path: string) => {
    console.log(path);
    /**
     * setShowPage(path.replace("/",""));
     */
    navigate(path)
  }
  React.useEffect(() => {
    toggleScreenSleep(location)
  }, [location]);
  const toggleScreenSleep = async (location: any) => {
    console.log('toggleScreenSleep', location.pathname);
    let instance = await gameRef?.current?.getInstance();
    if (location.pathname != '/home' && location.pathname != '/home/') {
      instance?.scene.sleep('MainScene');
    } else {
      instance?.scene.wake('MainScene');
    }
  }
  useEffect(() => {
    console.log('setGameLoading');
    dispatch(setGameLoading(true));
  }, [])
  useEffect(() => {
    /**
     * setInitialize(true)
     * setGame(Object.assign({}, gameConfig))
     */
    setTimeout(() => dispatch(setGameLoading(false)), 1000);
    window.addEventListener('resize', handleResize)
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);
  const handleResize = async () => {
    console.log('handleResize');
    setScreenSize({ width: window.innerWidth, height: window.innerHeight })
    Pubsub.publish(PUBSUB_WINDOW_RESIZE);
  }
  useEffect(() => {
    if (!initialize) {
      return;
    }
    if (!game) {
      setGame(Object.assign({}, gameConfig));
    }
    console.log('setGame');
  }, [initialize]);

  useEffect(() => {
    if (appWelcome)
      setTimeout(() => updateGameFunction(), 1000);
  }, [appWelcome]);

  useEffect(() => {
    if (!gameRef) return;
    setTimeout(() => updateGameFunction(), 0);
  }, [gameRef])

  const updateGameFunction = async () => {
    let instance = await gameRef?.current?.getInstance();
    let main: any = instance?.scene.scenes[0];
    // setTimeout(() => {
    //   Pubsub.publish(PUBSUB_PHASER_IN, true);
    // }, 2000);
    if (!(main && instance)) {
      return;
    }
    
    if (location.pathname != '/home' && location.pathname != '/home/') {
      toggleScreenSleep(location);
    }
    /**
     * console.log(instance,instance?.scene.scenes);
     */
  }
  return (
    <div {...props}
      className={classNames('home-phaser', location.pathname.toLowerCase().match(/\/home\/?/) && !menuOpen ? 'active' : 'inactive')}
    >
      {initialize ? (
        <>
          <IonPhaser ref={gameRef} game={game} initialize={initialize}
            style={{margin: 1}}
            placeholder={'Loading...'}
            onTouchStart={() => Pubsub.publish(PUBSUB_PHASER_IN, true)}
            onTouchEnd={() => {
              setTimeout(() => {
                Pubsub.publish(PUBSUB_PHASER_IN, false)
              }, 0)
            }}
            onMouseEnter={() => !isMobile && Pubsub.publish(PUBSUB_PHASER_IN, true)}
            onMouseLeave={() => !isMobile && Pubsub.publish(PUBSUB_PHASER_IN, false)}/>
        </>
      ) : (
        <></>
      )}
      {appWelcome && location.pathname.match(/\/home\/?/) && <SoundControl sound={'/sound/OMS_MainTheme.mp3'} />}
      <OmsModal modalName={'home-phase-modal-loading'} className={'coming-soon-modal'} open={!appWelcome} footer={null}
        closable={false} centered={true}>
        <div className={'welcome-wrapper'}>
          <div className={'welcome-inner-container'}>
            <Title level={2}>Welcome to One Magic School</Title>
            <Button type={'primary'} size={'large'}
              onClick={() => {
                dispatch(updateAppWelcome(true));
                checkUserLinkWallet();
              }}>Enter App
            </Button>
          </div>
        </div>
      </OmsModal>
      <OmsModal modalName={'home-phase-coming-soon'} open={openComingSoon} footer={null} closable={false}
        centered={true} className={'coming-soon-modal'}>
        <div className={'header-wrapper'}>
          <Title level={2}>{comingSoonTitle}</Title>
        </div>
        <div className={'body-wrapper'}>
          <span>{comingSoonContent}</span>
        </div>
        <div className={'footer-wrapper'}>
          <Button type={'primary'} size={'large'} onClick={() => {
            setOpenComingSoon(false);
          }}>OK</Button>
        </div>
      </OmsModal>
    </div>
  );
}
