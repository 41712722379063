/* eslint-disable quotes */
import { Button, Space, Typography, notification } from 'antd';
import CenterContainer from 'components/Layout/CenterContainer';
import { useAppSelector } from '../../app/hooks';
import { loadAllModulesSelector } from './SchoolModuleSlice';
import { ModulesData } from './SchoolModuleSlice'
import { loadUserGameInfoSelector } from './gameUserInfoSlide';
import { useEffect, useState } from 'react';
import MiningPopup from './Popup';
import React from 'react';
import { SchoolBuildPopup } from './SchoolBuildPopup';
import {
  SkillTreeGroup,
  SkillTree,
  SkillProvider,
  SkillType,
  SkillGroupDataType,
  SavedDataType
} from 'beautiful-skill-tree';

const { Title } = Typography;


function SchoolModule() {
  const allModulesInfo = useAppSelector(loadAllModulesSelector);
  const gameUserInfo = useAppSelector(loadUserGameInfoSelector);
  const [availableModule, setAvailableModule] = useState<ModulesData[]>([]);
  const [builtModules, setbuiltModule] = useState<ModulesData[]>([]);
  const [canBuildAffterModule, setcanBuildAffterModule] = useState<ModulesData[]>([]);
  const [openMining, setOpenMining] = useState<boolean>(false);
  const [mingingModuleId, setMingingModuleId] = useState<any>('');
  const [isOpenBuilding, setIsOpenBuilding] = useState<boolean>(false);
  const [chooseBuildModuleId, setChooseBuildModuleId] = useState<string>('');

  const [isNew, setIsNew] = useState(false);

  const handleMining = (resourceId: string) => {
    setOpenMining(true);
    setMingingModuleId(resourceId);
  }

  const data: SkillType[] = [
    {
      id: 'LB1',
      title: 'Lumper Camp 1',
      tooltip: {
        content:
          'This is a parent of the top node, and will locked while the parent isn’t in a selected state.',
      },
      children: [
        {
          id: 'MN1',
          title: 'Mineral Mine 1',
          tooltip: {
            content:
              'This is a parent of the top node, and will locked while the parent isn’t in a selected state.',
          },
          children: [
            {
              id: 'Aca1',
              title: 'Academic Office 1',
              tooltip: {
                content:
                  'This is a parent of the top node, and will locked while the parent isn’t in a selected state.',
              },
              children: [
                {
                  id: 'Lab1',
                  title: 'Lab 1',
                  tooltip: {
                    content:
                      'This is a parent of the top node, and will locked while the parent isn’t in a selected state.',
                  },
                  children: [],
                },
                {
                  id: 'RC1',
                  title: 'Rocky Cavern',
                  tooltip: {
                    content:
                      'This is a parent of the top node, and will locked while the parent isn’t in a selected state.',
                  },
                  children: [],
                },
                {
                  id: 'LIB1',
                  title: 'LIB 1',
                  tooltip: {
                    content:
                      'This is a parent of the top node, and will locked while the parent isn’t in a selected state.',
                  },
                  children: [],
                },
              ],
            }
          ],
        }
      ],
    },
  ];


  const renderModule = () => {

    return (

      <>
        <MiningPopup isOpen={openMining} setIsOpen={setOpenMining} miningModuleId={mingingModuleId} setMiningModuleId={setMingingModuleId} />
        {builtModules.map(module =>
          <Button key={module.id} name='buildBnt' style={{ backgroundColor: 'green' }}>{module.name}</Button>
        )}
        {availableModule.map(module =>
          <Button key={module.id} onClick={() => handleBuild(module.id)} name='canBuildBnt' style={{ backgroundColor: 'white' }}>{module.name}</Button>
        )}

        {canBuildAffterModule.map(module =>
          <Button key={module.id} style={{ backgroundColor: 'gray' }}>{module.name}</Button>
        )}
        <SchoolBuildPopup setModuleId={setChooseBuildModuleId} moduleId={chooseBuildModuleId} isOpen={isOpenBuilding} setIsOpen={setIsOpenBuilding} />
      </>
    )
  }
  const handleBuild = async (moduleId: string) => {
    setChooseBuildModuleId(moduleId);
    setIsOpenBuilding(true);
  }

  useEffect(() => {
    const buildModulesArr: ModulesData[] = [];
    const avaiModulesArr: ModulesData[] = [];
    const canBuildAffterModuleArr: ModulesData[] = [];
    console.log(gameUserInfo.modules)

    if (gameUserInfo.modules !== undefined && Object.keys(gameUserInfo.modules).length !== 0) {
      for (let i in gameUserInfo.modules) {
        let buildModule = allModulesInfo.find(module => module.id === i)
        if (buildModule != null) {
          buildModulesArr.push(buildModule);
        }
      }
      console.log(buildModulesArr);
      setbuiltModule(buildModulesArr);
    }


    if (gameUserInfo.modules === undefined || Object.keys(gameUserInfo.modules).length === 0) {
      console.log(gameUserInfo.modules);
      const modules = allModulesInfo.find(e => e.name === 'Lumber Camp 1');
      if (modules !== undefined)
        avaiModulesArr.push(modules);
      setAvailableModule(avaiModulesArr);
    } else {
      buildModulesArr.forEach(builtModule => {
        allModulesInfo.forEach(module => {
          if (!buildModulesArr.includes(module) && !avaiModulesArr.includes(module)) {
            module.required_modules.forEach(reqModule => {
              if (reqModule === builtModule.id)
                avaiModulesArr.push(module);
            })
          }
        })
      });
      setAvailableModule(avaiModulesArr);
    }

    avaiModulesArr.forEach(avaimodule => {
      allModulesInfo.forEach(module => {
        if (!avaiModulesArr.includes(module) && !canBuildAffterModuleArr.includes(module)) {
          module.required_modules.forEach(reqModule => {
            if (reqModule === avaimodule.id)
              canBuildAffterModuleArr.push(module);
          })
        }
      })
    });

    setcanBuildAffterModule(canBuildAffterModuleArr);
  }, [allModulesInfo, gameUserInfo, isNew])

  const savedData: SavedDataType = {
    'LB1': {
      optional: false,
      nodeState: 'unlocked',
    },
    'MN1': {
      optional: false,
      nodeState: 'locked',
    },
  };

  return (

    <CenterContainer className={'account-info-container'} isVisible={true} width={700}>
      <div className={'header'}>
        <Title level={1}>Aldoria</Title>
      </div>

      <Space className='module-button' wrap direction="horizontal" size="middle" style={{ padding: '20px' }}>
        {renderModule()}

      </Space>

    </CenterContainer>
    // <CenterContainer className={'account-info-container'} isVisible={true} width={700}>
    //   <div className={'header'}>
    //     <Title level={1}>Aldoria</Title>
    //   </div>
    //   <Space className='module-button' wrap direction="horizontal" size="middle" style={{ padding: '20px' }}>
    //     <SkillProvider>
    //       <SkillTreeGroup>
    //         {({ skillCount }: SkillGroupDataType) => (
    //           <SkillTree
    //             treeId="first-tree"
    //             title="Skill Tree"
    //             data={data}
    //             collapsible
    //             description="My first skill tree"
    //             savedData={savedData}
    //           />
    //         )}
    //       </SkillTreeGroup>
    //     </SkillProvider>;
    //   </Space>
    // </CenterContainer>
  );
}

export default SchoolModule;
