import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { API_ALL_SCHOOL_MODULE } from 'helpers/config';
import { notification } from 'antd';
import { postJSON } from 'helpers/common';


export interface CostInfo {
  [key: string] : number;
}
export interface YieldResources {
  resource_id: string,
  resource_name: string,
  value: number,
  rate: number,
  max: number,
  time: string, 
  cost: CostInfo;
}

export interface RequiredResource {
  resource_id: string,
  resource_name:string,
  value: number
}

export interface YieldAttribute {
  attribute_id: string,
  attribute_name: string,
  value: number,
  rate: number,
  max: number,
  time: string
}
export interface ModulesData {
  yield_button: string;
  _id: string,
  id: string,
  name: string,
  level: integer,
  required_modules: [string],
  required_resources: [RequiredResource],
  yield_resources: YieldResources[],
  yield_attribute: YieldAttribute[],
  replace_module: string,
  required_stats: any[],
}


interface SchoolModulesState {
  status: 'loading' | 'ready',
  allModules: ModulesData[]
}

const initialState: SchoolModulesState = {
  allModules: [],
  status: 'loading'
};

export const schoolModulesSlice = createSlice({
  name: 'allModules',
  initialState: initialState,
  reducers: {
    
  },
  extraReducers: (builder) => builder
    .addCase(loadAllModules.pending, (state) => {
      state.status = 'loading';
      state.allModules = [];
    })
    .addCase(loadAllModules.rejected, (state) => {
      state.status = 'ready';
      notification.error({message: 'Somethings wrong when loading School Modules'});
    })
    .addCase(loadAllModules.fulfilled, (state, action: PayloadAction<ModulesData[]>) => {
      state.allModules = action.payload;
      state.status = 'ready';
    })
})

export const loadAllModules = createAsyncThunk('allModules/load', async () => {
  const resData = await postJSON(API_ALL_SCHOOL_MODULE);
  return resData.data;
})

export default schoolModulesSlice.reducer;

export const loadAllModulesSelector = (state: RootState) => state.schoolModules.allModules;
export const allModulesLoadingStatusSelector = (state: RootState) => state.schoolModules.status;
