import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { GET_CHARACTER_INFO } from 'helpers/config';
import { notification } from 'antd';
import { postJSON } from 'helpers/common';


export interface metadata {
  _id: number,
  name: string,
  description: string,
  image: string,
  dna: string,
  edition: number,
  date: number,
  attributes: [
      {
          trait_type: string,
          value: string
      },
  ],
  compiler: string
}

export interface CharacterInfo {
  effect: [],
  equipments: [],
  metadata: metadata,
  mining_end_time: number,
  mining_module: string,
  mining_start_time: number,
  learning_start_time: number,
  mining_resource: string,
  nft: boolean,
  nft_id: number,
  owner: string,
  stats: any,
  _id: string,
  block: number
}

interface characterInfoState {
  characterInfo?: CharacterInfo[],
  status: string
}

const initialState: characterInfoState = {
  status: 'loading',
  characterInfo: []
}

export interface MiningStatus {
  charId: string,
  module_id: string,
  mining_resource: string
}



export const characterInfoSlide = createSlice({
  name: 'GetAllCharacterInfo',
  initialState: initialState,
  reducers: {
    updateCharacterMining: (state, action: PayloadAction<MiningStatus>) => {
      state.characterInfo?.forEach(char => {
        if (char._id === action.payload.charId) {
          char.mining_start_time = Date.now();
          char.mining_module = action.payload.module_id;
          char.mining_resource = action.payload.mining_resource;
        }
      }
      )
    }, 
    endCharacterMining: (state, action: PayloadAction<string>) => {
      state.characterInfo?.forEach(char => {
        if (char._id === action.payload) {
          char.mining_start_time = 0;
          char.learning_start_time = 0;
          char.mining_module = '';
          char.mining_resource = '';
          
        }
      }
      )
    },
    updateCharacterLearning: (state, action: PayloadAction<MiningStatus>) => {
      state.characterInfo?.forEach(char => {
        if (char._id === action.payload.charId) {
          char.learning_start_time = Date.now();
          char.mining_module = action.payload.module_id
        }
      }
      )
    },
    
    resetcharacterInfo: () => initialState

  },
  extraReducers: (builder) => builder
    .addCase(loadCharacterInfo.pending, (state) => {
      state.status = 'loading';
      state.characterInfo = [];
    })
    .addCase(loadCharacterInfo.rejected, (state) => {
      state.status = 'ready';
      notification.error({message: 'Somethings wrong when loading Character Info'});
    })
    .addCase(loadCharacterInfo.fulfilled, (state, action: PayloadAction<CharacterInfo[]>) => {
      action.payload.map(info => {
        const char = {
          effect: info.effect,
          equipments: info.equipments,
          metadata: info.metadata,
          mining_end_time: info.mining_end_time,
          mining_module: info.mining_module,
          mining_start_time: info.mining_start_time,
          learning_start_time: info.learning_start_time,
          mining_resource: info.mining_resource,
          nft: info.nft,
          nft_id: info.nft_id,
          owner: info.owner,
          stats: info.stats,
          _id: info._id,
          block: info.block
        }

        state.characterInfo?.push(char);
      })
      state.status = 'ready';
    })
})

export const loadCharacterInfo = createAsyncThunk('GetAllCharacterInfo/load', async () => {
  console.log('character info load');
  const resData = await postJSON(GET_CHARACTER_INFO);
  return resData.data;
  });

export const {
  updateCharacterMining,
  endCharacterMining,
  updateCharacterLearning,
  resetcharacterInfo
} = characterInfoSlide.actions;

export default characterInfoSlide.reducer;
export const loadCharacterInfoSelector = (state: RootState) => state.characterInfo.characterInfo;
export const loadCharacterInfoStatus = (state: RootState) => state.characterInfo.status;