import { Button, Space, Typography } from 'antd';
import CenterContainer from 'components/Layout/CenterContainer';
import { useAppSelector } from '../../app/hooks';
import { loadAllModulesSelector } from '../SchoolModule/SchoolModuleSlice';
import { ModulesData } from '../SchoolModule/SchoolModuleSlice'
import { loadUserGameInfoSelector } from '../SchoolModule/gameUserInfoSlide';
import { useEffect, useState, useRef } from 'react';
import MiningPopup from '../SchoolModule/Popup';
import React from 'react';
import { SchoolBuildPopup } from '../SchoolModule/SchoolBuildPopup';
import { gameLoadingSelector, setGameLoading } from './AldoriaSlice';
import AldoriaScene from './AldoriaScene';
import { useAppDispatch } from '../../app/hooks';
import Phaser from 'phaser';
import Pubsub from 'pubsub-js';
import classNames from 'classnames';
import { IonPhaser, GameInstance } from '@ion-phaser/react';

import { isMobile } from 'react-device-detect';
import OmsModal from 'components/CustomComponent/OmsModal';
const { Title } = Typography;
const gameConfig: GameInstance = {
  width: '100%',
  height: '100%',
  type: Phaser.AUTO,
  scale: {
    // mode: Phaser.Scale.RESIZE,
    autoCenter: Phaser.Scale.CENTER_BOTH,
    mode: Phaser.Scale.RESIZE,
  },

  render: {
    antialias: false,
    pixelArt: true,
    roundPixels: true,
  },
  transparent: true,
  scene: AldoriaScene,
  plugins: {
    scene: [
      { key: 'SpinePlugin', plugin: window.SpinePlugin, mapping: 'spine' }
    ],
  }
};
const PUBSUB_ALDORIA_WINDOW_RESIZE = 'aldoria:window:resize';
const PUBSUB_ALDORIA_OPEN_BUILDER = 'aldoria:open:builder';
const PUBSUB_ALDORIA_NAVIGATE_TO = 'aldoria:navigate:to';
const PUBSUB_ALDORIA_PHASER_IN = 'aldoria:phaser:in';
const PUBSUB_ALDORIA_OPEN_MODULE = 'aldoria:open:module';
const PUBSUB_ALDORIA_BUILT_MODULE = 'aldoria:built:module';
function Aldoria() {
  const dispatch = useAppDispatch();
  const [showBuilder, setShowBuilder] = useState<boolean>(false);
  const allModulesInfo = useAppSelector(loadAllModulesSelector);
  const gameUserInfo = useAppSelector(loadUserGameInfoSelector);
  const gameRef = useRef<HTMLIonPhaserElement>(null);
  const [game, setGame] = useState<GameInstance>();
  const [availableModule, setAvailableModule] = useState<ModulesData[]>([]);
  const [builtModules, setbuiltModule] = useState<ModulesData[]>([]);
  const [canBuildAffterModule, setcanBuildAffterModule] = useState<ModulesData[]>([]);
  const [openMining, setOpenMining] = useState<boolean>(false);
  const [mingingModuleId, setMingingModuleId] = useState<any>('');
  const [isOpenBuilding, setIsOpenBuilding] = useState<boolean>(false);
  const [chooseBuildModuleId, setChooseBuildModuleId] = useState<string>('');
  const [isNewModuleBuild, setIsNewModuleBuild] = useState<boolean>(false);
  const initialize = !useAppSelector(gameLoadingSelector);
  const [, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const handleMining = (resourceId: string) => {
    setOpenMining(true);
    setMingingModuleId(resourceId);
  }
  useEffect(() => {
    console.log('setGameLoading');
    dispatch(setGameLoading(true));
  }, [])
  useEffect(() => {
    if(showBuilder||isOpenBuilding){
      Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, false)
    }
    else{
      Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, true)
    }
  },[showBuilder,isOpenBuilding]);
  useEffect(() => {
    /**
     * setInitialize(true)
     * setGame(Object.assign({}, gameConfig))
     */
    setTimeout(() => dispatch(setGameLoading(false)), 1000);
    window.addEventListener('resize', handleResize)
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);
  const handleResize = async () => {
    //console.log('handleResize');
    setScreenSize({ width: window.innerWidth, height: window.innerHeight })
    Pubsub.publish(PUBSUB_ALDORIA_WINDOW_RESIZE);
  }
  Pubsub.subscribe(PUBSUB_ALDORIA_OPEN_BUILDER, (topic: string, content: any) => {
    console.log('PUBSUB_OPEN_BUILDER:running')
    setShowBuilder(true);
    Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, false)
  })
  Pubsub.subscribe(PUBSUB_ALDORIA_OPEN_MODULE, (topic: string, content: any) => {
    console.log('PUBSUB_OPEN_MODULE:running');
    console.log(content.id);
    handleMining(content.id);
    Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, false)
  })
  useEffect(() => {
    if (!initialize) {
      return;
    }
    if (!game) {
      setGame(Object.assign({}, gameConfig));
    }
    console.log('setGame');
  }, [initialize]);



  useEffect(() => {
    if (!gameRef) return;
    setTimeout(() => updateGameFunction(), 0);
  }, [gameRef])

  const updateGameFunction = async () => {
    let instance = await gameRef?.current?.getInstance();
    let main: any = instance?.scene.scenes[0];
    if (!(main && instance)) {
      return;
    }
    instance?.scene.wake('MainScene');
    Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, true);
    // if (location.pathname != '/home' && location.pathname != '/home/') {
    //   toggleScreenSleep(location);
    // }
    /**
     * console.log(instance,instance?.scene.scenes);
     */
  }
  const renderModule = () => {

    return (

      <div style={{ position: 'relative' }}>
        {/* close builder button on top right corner */}
        <Button onClick={() => setShowBuilder(false)} style={{ float: 'right' }}>X</Button>
        <MiningPopup isOpen={openMining} setIsOpen={setOpenMining} miningModuleId={mingingModuleId} setMiningModuleId={setMingingModuleId} />
        {builtModules.map(module =>
          <Button key={module.id} name='buildBnt' style={{ backgroundColor: 'green' }}>{module.name}</Button>
        )}
        {availableModule.map(module =>
          <Button key={module.id} onClick={() => handleBuild(module.id)} name='canBuildBnt' style={{ backgroundColor: 'white' }}>{module.name}</Button>
        )}

        {canBuildAffterModule.map(module =>
          <Button key={module.id} style={{ backgroundColor: 'gray' }}>{module.name}</Button>
        )}
        <SchoolBuildPopup setModuleId={setChooseBuildModuleId} moduleId={chooseBuildModuleId} isOpen={isOpenBuilding} setIsOpen={setIsOpenBuilding} />
      </div>
    )
  }
  const handleBuild = async (moduleId: string) => {
    setChooseBuildModuleId(moduleId);
    setIsOpenBuilding(true);
  }

  useEffect(() => {
    const buildModulesArr: ModulesData[] = [];
    const avaiModulesArr: ModulesData[] = [];
    const canBuildAffterModuleArr: ModulesData[] = [];
    //console.log(gameUserInfo.modules)
    // pubsub.publish(PUBSUB_BUILT_MODULE, gameUserInfo.modules);
    // setTimeout(() => {
    //   Pubsub.publish(PUBSUB_ALDORIA_BUILT_MODULE, 'AT');
    // }, 2000)
    setTimeout(() => {
      Pubsub.publish(PUBSUB_ALDORIA_PHASER_IN, true)
    },2000)
    
    if(gameUserInfo && gameUserInfo.modules && Object.keys(gameUserInfo.modules).length !== 0){
    for(let i of Object.keys(gameUserInfo.modules)){
      setTimeout(() => {
        Pubsub.publish(PUBSUB_ALDORIA_BUILT_MODULE, i);
      }, 2000)
     
      //Pubsub.publish(PUBSUB_BUILT_MODULE, i);
    }}


    if (gameUserInfo.modules !== undefined && Object.keys(gameUserInfo.modules).length !== 0) {
      for (let i in gameUserInfo.modules) {
        let buildModule = allModulesInfo.find(module => module.id === i)
        if (buildModule != null) {
          buildModulesArr.push(buildModule);
        }
      }
      console.log(buildModulesArr);
      setbuiltModule(buildModulesArr);
    }


    if (gameUserInfo.modules === undefined || Object.keys(gameUserInfo.modules).length === 0) {
      console.log(gameUserInfo.modules);
      const modules = allModulesInfo.find(e => e.name === 'Lumber Camp 1');
      if (modules !== undefined)
        avaiModulesArr.push(modules);
      setAvailableModule(avaiModulesArr);
    } else {
      buildModulesArr.forEach(builtModule => {
        allModulesInfo.forEach(module => {
          if (!buildModulesArr.includes(module) && !avaiModulesArr.includes(module)) {
            module.required_modules.forEach(reqModule => {
              if (reqModule === builtModule.id)
                avaiModulesArr.push(module);
            })
          }
        })
      });
      setAvailableModule(avaiModulesArr);
    }

    avaiModulesArr.forEach(avaimodule => {
      allModulesInfo.forEach(module => {
        if (!avaiModulesArr.includes(module) && !canBuildAffterModuleArr.includes(module)) {
          module.required_modules.forEach(reqModule => {
            if (reqModule === avaimodule.id)
              canBuildAffterModuleArr.push(module);
          })
        }
      })
    });

    setcanBuildAffterModule(canBuildAffterModuleArr);
    setIsNewModuleBuild(false);
  }, [allModulesInfo, gameUserInfo, isNewModuleBuild])

  return (

    <div
      className={classNames('home-phaser', 'active')}
    >
      {initialize ? (
        <>
          <IonPhaser ref={gameRef} game={game} initialize={initialize}
            style={{ margin: 1 }}
            placeholder={'Loading...'}
           
            />
        </>
      ) : (
        <></>
      )}
      {/* floating build button */}
      <Button onClick={() => setShowBuilder(true)} style={{ position: 'absolute', bottom: 10, right: '48%', height: 50, width:90 }}>Build</Button>
      <OmsModal modalName={'home-phase-modal-loading'} className={'coming-soon-modal'} open={showBuilder} footer={null}
        closable={false} centered={true}>
        {renderModule()}
      </OmsModal>
      <MiningPopup isOpen={openMining} setIsOpen={setOpenMining} miningModuleId={mingingModuleId} setMiningModuleId={setMingingModuleId} />
    </div>
  );
}

export default Aldoria;
